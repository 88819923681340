export const backgroundHeight = () => {
    const bodySize = [...document.getElementsByTagName('body')][0]
    const title =
        [...document.getElementsByClassName('gradient-header')][0] ?? false

    const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            const documentHeight = Math.round(entry.contentRect.height)
            if (title) {
                title.style['background-size'] =
                    '100vw ' + documentHeight + 'px'
            }
        })
    })

    resizeObserver.observe(bodySize)
}
