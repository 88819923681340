export const cardFilter = () => {
    var input, filter, services, cards, clearbutton

    input = document.getElementById('filterinput')
    services = document.getElementById('serviceContainer')
    cards = services ? [...services.getElementsByClassName('col')] : [] // type conversion does not allow optional chaining (afaik), hence the ternary

    input?.addEventListener('input', () => {
        filter = input.value.toUpperCase()
        cards.forEach((e) => {
            if (
                !e.innerText.toUpperCase().match(filter) &&
                filter.length >= 3
            ) {
                e.classList.add('d-none')
            } else {
                e.classList.remove('d-none')
            }
        })
    })

    clearbutton = document.getElementById('clearbutton')

    clearbutton?.addEventListener('click', () => {
        input.value = ''
        cards.forEach((e) => e.classList.remove('d-none'))
    })
}
